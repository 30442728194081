import React from 'react';

class PanierHoverObjet extends React.Component {
    constructor(props) {
        super(props);

        this.deletePanier = this.deletePanier.bind(this);
        this.handleClose = this.handleClose.bind(this);

        const styleDefault = {
            display: "block",
        }

        const prix_affiche = this.props.detailProduit.price_ht*this.props.detailProduit.quantity;

        this.state = {
  
            nom:            this.props.detailProduit.name,
            id:             this.props.detailProduit.id,
            quantite:       this.props.detailProduit.quantity,
            prix_ht:        this.props.detailProduit.price_ht,
            prix_affiche:   prix_affiche,
            // marque à faire
            marque:         this.props.mark,
            // image à faire
            image: "Produit3.png",

            style: styleDefault,

        };
    }

    deletePanier(id) {
 
        var request = $.ajax({
            url : "/ajax/delete/product",
            method: "POST",
            dataType: "JSON",
            data:{
                id: id
            },
            success: function(data){
                callBackDataDelete(id)
                $(".product-" + id).css('display', 'none');
                $('#notif-panier').replaceWith("<span id='notif-panier'>" + data + "</span>");
            },
            error: function () {
                console.log("Erreur");
            }
        });

        const {callBackDataDelete} = this.props;

       /* if(request){
            this.setState({
                style: {display: "none"},
            });
            this.handleClose();
            
        }*/
        
    }

    handleClose(){
        this.props.close(this)
    }

    render() {
        return (
            <div className={`row product-${this.state.id}`} >
                <img src={ `/images/produits/${ this.state.image }` } alt="Visuel"/>
                <div className="objet-desc">
                    <p className="titre">{this.state.nom}</p>
                    <p>Quantité : {this.state.quantite}</p>
                    <p className="prix">{this.state.prix_affiche}&euro;</p>
                </div>
                <i className="fa fa-times" onClick={() => this.deletePanier(this.state.id)}></i>
            </div>
        )
    }
}

export default PanierHoverObjet;