require('../css/app.scss');

import React from 'react';
import ReactDOM from 'react-dom';


//PANIER
import Panier from './components/panier/Panier';

if(document.getElementById("panier-plein")) {
    ReactDOM.render(<Panier />, document.getElementById("panier-plein"));
}

//NAV PANIER HOVER
import NavPanierHover from './components/panier/navHover/NavPanierHover';//'./Components/panier/navHover/NavPanierHover';
ReactDOM.render(<NavPanierHover />, document.getElementById("panier-hover"));
