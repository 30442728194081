import React from 'react';
import PanierHoverObjet from './PanierHoverObjet';

class NavPanierHover extends React.Component {
    constructor() {
        super();

        const nbObj = productPanier ? productPanier.length : 0;

        this.callbackDeletValue = this.callbackDeletValue.bind(this);
        this.handleClose = this.handleClose.bind(this)


        this.state = {
            total: 0,
            nbObj: nbObj,   
            totalPrice: {},
            /*
            prices: [9.99, 12.49],  //changer
            listObj: [  //remplacer par valeur bo avec fonction et boucle for
                <PanierHoverObjet
                titre={"Le Cirage Noir"}
                prix={"9,99"}   //en string !
                close={this.handleClose}
                image={"Produit1.png"}
                key={0}
                id={0}
                />,

                <PanierHoverObjet
                titre={"Brosses Palot x2"}
                prix={"12,49"}   //en string !
                close={this.handleClose}
                image={"Produit2.png"}
                key={1}
                id={1}
                />
            ]
            */
        }
        console.log(this.state.nbObj)
    }

//  * initialisation de totalPrice id: prix*quantité *  //
    componentWillMount(){
        var tabDeBase = {};
        var totalPrice = this.state.totalPrice;

        for(let i=0; i < productPanier.length; i++) {

            var id = productPanier[i].id;
            var sommePrixHt = Number(productPanier[i].price_ht,10)*Number(productPanier[i].quantity,10);
            var toTotalDeBase = {[id]: sommePrixHt};
            
            
            tabDeBase = { 
                ...tabDeBase,
                ...toTotalDeBase
            };
               
        }this.setState({
            totalPrice:{
                ...totalPrice,
                ...tabDeBase
            }
        });
    };

    handleClose(objet) {
        this.setState({
            nbObj: this.state.nbObj-1,
        })
        console.log(this.state.nbObj)

    }
    
// *  fait remonter des données du state depuis le composant enfant  * //
    callbackDeletValue(id) {
        this.deleteValue(id);
    }

    deleteValue(id) {
        var newTotalPrice = this.state.totalPrice;
        var id = id;

        delete newTotalPrice[id];
        
        this.setState({
            totalPrice : {
                ...newTotalPrice,
                
            }
            
        });console.log('le nouveau state:',newTotalPrice)

    }

    calculTotal() {

        const tva = 20     
        var totalValues = Object.values(this.state.totalPrice);
        var newTotal = 0;

        for(let i=0; i < totalValues.length; i++) {
            newTotal += Number(totalValues[i])
        }
        this.state.tva = newTotal * tva / 100;
        this.state.total = newTotal;
        this.calculTotalTtc();
    }

    calculTotalTtc() {
        this.state.total = this.state.total + this.state.tva;
    }
//  * verifie si le panier est vide, si oui affiche le bloc adapte *  //
    checkVide() {  
        if(this.state.nbObj == 0) {
            document.getElementById("nav-panier-plein").style.display = "none";
            document.getElementById("nav-panier-vide").style.display = "block";
        } else {
            this.calculTotal()
        }
        return
    }

    render() {
        return (
            <div id="panier-hover-contain">
                                
                <div id="nav-panier-vide" className="row">
                    <p>Votre panier est vide</p>
                </div>
                
                <div id="nav-panier-plein">
                        
                    {productPanier && productPanier.map(products => 
                        <PanierHoverObjet 
                            key={products.id} 
                            detailProduit={products}
                            callBackDataDelete={this.callbackDeletValue} 
                            close={this.handleClose}
                            />
                    )}
                    {this.checkVide()}
                    
                    <p className="total">Total ttc : <span className="prix">{this.state.total}&euro;</span><br/>
                    Tva : <span className="prix">{this.state.tva}&euro;</span></p>
                    <a href="/shop/panier">Voir mon panier</a>
                </div>
                
            </div>
        )
    }
}

export default NavPanierHover;