import React from 'react';

class MsgValid extends React.Component {
    render(){
        return(
            <p >Carte cadeau valide</p>
        )
    }
}

export default MsgValid;