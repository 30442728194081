import React from 'react';
import PanierObjet from './PanierObjet';
import MsgNotValid from './MsgError';
import MsgValid from './MsgSuccess';

class Panier extends React.Component {

    constructor(props) {
        super(props);
 
        const total_ht =0;
        const nbObj = product.length;
        this.callBackNewValues = this.callBackNewValues.bind(this);
        this.callbackDeletValue = this.callbackDeletValue.bind(this);

        this.state = {
         
            total: total_ht,
            nbObj: nbObj,   
            tva: 0,
            totalPrice: {},
            giftCardCode: 0,
            checked: 'pending'

        };
        this.handleChange = this.handleChange.bind(this);

    }

//  * initialisation de totalPrice id: prix*quantité *  //
    componentWillMount(){
        var tabDeBase = {};
        var totalPrice = this.state.totalPrice;

        for(let i=0; i < product.length; i++) {

            var id = product[i].id;
            var sommePrixHt = Number(product[i].price_ht,10)*Number(product[i].quantity,10);
            var toTotalDeBase = {[id]: sommePrixHt};
            
            
            tabDeBase = { 
                ...tabDeBase,
                ...toTotalDeBase
            };
               
        }this.setState({
            totalPrice:{
                ...totalPrice,
                ...tabDeBase
            }
        });
    };
    
// *  fait remonter des données du state depuis le composant enfant  * //
    callBackNewValues(id, sommePrix) {   
        
        this.updateTotaPrice(id,sommePrix);    
        
    }
    
// *  fait remonter des données du state depuis le composant enfant  * //
    callbackDeletValue(id) {
        this.deleteValue(id);
    }

    deleteValue(id) {
        var newTotalPrice = this.state.totalPrice;
        var id = id;

        delete newTotalPrice[id];
        
        this.setState({
            totalPrice : {
                ...newTotalPrice,
                
            }
            
        });console.log('le nouveau state:',newTotalPrice)

    }

    updateTotaPrice(id, sommePrix) {
        var totalPrice = this.state.totalPrice;
        var id = id;
        var sommePrix = Number(sommePrix,10);
        sommePrix = sommePrix.toFixed(2);
        var newTab = {[id] : sommePrix};

        this.setState({
            totalPrice : {
                ...totalPrice,
                ...newTab
            }
            
        })

        console.log('mise a jours de totalPrice:',totalPrice);
    }
    
    calculTotal() {

        var tva = 20     
        var totalValues = Object.values(this.state.totalPrice);
        var newTotal = 0;
        var totalHt = 0;
 
        for(let i=0; i < totalValues.length; i++) {
            newTotal += Number(totalValues[i])
        }
        
        totalHt = newTotal / (1+tva/100);

        var tvaTemp = totalHt * tva / 100;
        this.state.tva = tvaTemp.toFixed(2);
        this.state.total = newTotal.toFixed(2);
    }

    
//  * verifie si le panier est vide, si oui affiche le bloc adapte *  //
    checkVide() {  
        if(this.state.nbObj == 0) {
            document.getElementById("panier-plein").style.display = "none";
            document.getElementById("panier-vide").style.display = "flex";
        } else {
            this.calculTotal();  
        }
        return
    }

    msgCode() {
        if(this.state.checked == 'not-valid') {
            return(
            <MsgNotValid />
            )
        }else if(this.state.checked == 'valid'){
            return(
            <MsgValid />
            )
        }
    }

    handleChange() {
        if(event.target.value < 1){
            this.setState({
                checked: 'pending'
            })
        }
        this.setState({
            giftCardCode: event.target.value
        }) 
    }

    handleApplyCode(gift) {
        //document.getElementById(item).style.display = "block"
        
        if(gift){
            let uri = 'https://www.gentlemen1919.com/shop/panier/ajax/check/gift/' + gift + '';
            //let uri = 'http://gentlemen.uxia-agency.fr/shop/panier/ajax/check/gift/' + item + '';
        
            fetch(uri, {
                method: "POST",
                mode: 'cors',
                headers:{
                    'Access-Control-Allow-Origin':'*'
                }
            })
            .then(data => data.json())
            .then(data => {
                if(data){
                    if(data.check){
                        this.setState({
                            checked: 'valid'
                        })
                    }else if(!data.check){
                        this.setState({
                            checked: 'not-valid'
                        })
                    }
                }
            });
        }

    }
   
    render() {   
        console.log(product)
        return (
            <div>

                <table>
                    <tbody>  
                        {product.map(products => 
                            <PanierObjet 
                                key={products.id} 
                                detailProduit={products}
                                callBackData={this.callBackNewValues}
                                callBackDataDelete={this.callbackDeletValue} />
                            )}
                    </tbody>                
                </table>

                {this.checkVide()}
                {/*            
                <div id="reduc" className="row">
                    <div>
                        <label htmlFor="cadeau">Carte cadeau</label><br/>
                        <input type="text" id="code-cadeau" name="code-cadeau" placeholder="xxxx-xxxx-xxxx-xxxx" size="30" onChange={this.handleChange}/>
                        <button type="button" className="bouton" onClick={() => this.handleApplyCode(this.state.giftCardCode)}>Appliquer</button><br/>
                        
                        {/*  <p className="not applied" id="carte">Carte cadeau invalide</p>  
                        {this.msgCode()}
                    </div>
                    
                           
                    <div>
                        <label htmlFor="promo">Code promo</label><br/>
                        <input type="text" id="promo" name="code-promo" placeholder="Ex : GENTLEMAN50" size="30"/>
                        <button type="button" className="bouton" onClick={() => this.handleApplyCode("code")}>Appliquer</button><br/>
                        <p className="applied" id="code">Code promo appliqué&nbsp; <strong>-50&#x25;</strong></p>
                    </div>
                </div>
            */}
           
                <div id="total" className="row">
                    <div>
                        <h2>Total ttc: &nbsp; &nbsp; <span  id="prix-total">{this.state.total}&euro;</span></h2>
                        <span className="tva">TVA : &nbsp; <span className="prix">{this.state.tva}&euro;</span></span>
                    </div>
                    <a href="/commande" disabled="disabled" className="bouton">Paiement</a>
                </div>
            
            </div>
        )
    }
}

export default Panier;