import React from 'react';
import $ from 'jquery';
import parse from 'html-react-parser';

class PanierObjet extends React.Component {
    constructor(props) {
        super(props);

        const styleDefault = {
            display: "block",
        }
        

        const prix_afficheTemp = this.props.detailProduit.price_ht*this.props.detailProduit.quantity;
        const prix_affiche = prix_afficheTemp.toFixed(2);

        this.state = {
  
            nom:                this.props.detailProduit.name,
            type:                this.props.detailProduit.type,
            id:                 this.props.detailProduit.id,
            description:        this.props.detailProduit.body,
            quantite:           this.props.detailProduit.quantity,
            prix_ttc:           this.props.detailProduit.price_ht,
            prix_affiche:       prix_affiche,
            // marque à faire
            marque:             this.props.mark,
            // image à faire
            image:              this.props.detailProduit.picture,

            style:               styleDefault,
        };



         this.handleChange = this.handleChange.bind(this);
         this.deletePanier = this.deletePanier.bind(this);

    }

    handleChange() {
        const {callBackData} = this.props;

        var prixBase = this.state.prix_ttc;
        var id = this.state.id;
        var nouveauPrixTemp = prixBase*event.target.value;
        var nouveauPrix = nouveauPrixTemp.toFixed(2);

        this.setState({
            prix_affiche: nouveauPrix,
            quantite: event.target.value
           
        });
        this.updatePanier(event.target.value,id);
        
        callBackData(id, nouveauPrix);
    }

    updatePanier(quantity, idProduct) {
        var request = $.ajax({
            url : "/ajax/update/product",
            method: "POST",
            dataType: "JSON",
            data:{
                quantity: quantity,
                idProduct: idProduct,
            },
            success: function(data){

            },
            error: function () {
                console.log("Erreur");
            }
        });

    }

    deletePanier(idProduct) {
        const {callBackDataDelete} = this.props;

        var request = $.ajax({
            url : "/ajax/delete/product",
            method: "POST",
            dataType: "JSON",
            data:{
                idProduct: idProduct
            },
            success: function(data){
                
            },
            error: function () {
                console.log("Erreur");
            }
        });

        if(request){
                this.setState({
                style: {display: "none"},
                
            });callBackDataDelete(idProduct);
            
        }
        
    }
    isEmpty(obj) {
        for(var prop in obj) {
          if(obj.hasOwnProperty(prop)) {
            return false;
          }
        }
      
        return JSON.stringify(obj) === JSON.stringify({});
      }
    formatedDescription() {
        let desc =  parse(this.state.description);
        if(desc){
            if(desc.props && desc.props.children !== undefined){
                return desc.props.children
            }else{
                return this.state.description
            }
        }else{
            return ""
        }
    }
    render() {
        console.log(this.formatedDescription())
        return (
            <tr class='produit' style={this.state.style} className="col-md-12">
                    
                <td className="col-xs-8">
                    <div>
                        <img src={ `/media/product/${ this.state.image }`} alt="Photo"/>
                    </div>
                    <h2>{this.state.nom}</h2>
                    <span><p>{this.formatedDescription()}</p></span>
                    <p><span className="stock">✓ En stock</span></p>
                </td>
                <td className="col-xs-2"><p className="prix">{this.state.prix_affiche}&euro;</p></td>
                <td className="col-xs-2">
                    
                        <p className="td-quantite">
                            <label htmlFor="quantite">Quantité : </label>
                            {this.state.type  !== 2 ?
                                <input id="quantite" name="quantite" type="number" min="1" max="100" value={this.state.quantite} onChange={this.handleChange} />
                                : <input id="quantite" name="quantite" value={1} />
                            }
                        </p>
                    <i id='onClose' className="fa fa-times" onClick={() => this.deletePanier(this.state.id)}></i>
                </td>

            </tr>
        )
    }
}

export default PanierObjet;